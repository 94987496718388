<template>
	<div
		class="flex flex-col group relative bg-white shadow rounded-xl"
		:class="[
			{
				'z-20': state.isMenuOpen,
				'ring-4 ring-sky-500': isInBasket,
			},
		]"
	>
		<i
			v-if="isInTransit"
			class="text-red-500 transition-all absolute fa fa-2xl fa-truck-fast z-10 opacity-100 group-hover:opacity-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
		></i>
		<div
			:class="
				isInTransit
					? 'transition-all opacity-[35%] grayscale group-hover:grayscale-0 group-hover:opacity-100'
					: ''
			"
		>
			<div class="group relative">
				<div class="flex">
					<p
						v-if="props.balance && (props.balance?.available ?? 0) > 1"
						class="flex justify-center items-center px-2 py-2 leading-none text-sm bg-black/80 rounded-lg text-white shadow-md shadow-gray-400 absolute top-2 right-2 z-10"
					>
						<i class="fa-regular fa-layer-group mr-2 p-0 m-0 leading-none"></i>
						{{ props.balance?.available ?? 0 }}
					</p>
					<button
						type="button"
						class="justify-center hidden group-hover:flex px-2 py-1 bg-black/80 rounded-lg absolute bottom-2 right-2 z-10 text-white"
						:class="isUsersFavoriteAsset ? '!inline-flex' : ''"
						@click="handleFavorite(!isUsersFavoriteAsset, asset.asset_identifier)"
					>
						<HeartIconSolid v-if="isUsersFavoriteAsset" class="h-4 w-4" />
						<HeartIcon v-else class="h-4 w-4" />
					</button>
				</div>

				<div class="rounded-t-xl overflow-hidden shadow">
					<router-link :to="{ name: 'asset', params: { slug: props.slug, assetId: props.asset.id } }">
						<img
							:src="$cdnify(props.asset.image, props.imageWidth)"
							@load="state.loaded = true"
							loading="lazy"
							class="w-full ease-in-out duration-300 hover:scale-110"
							:class="[
								{
									'cursor-no-drop': basketStore.isBasketModeEnabled && isInBasket,
									'cursor-copy': basketStore.isBasketModeEnabled && !isInBasket,
								},
							]"
							@click="imageClick"
						/>
					</router-link>
				</div>
			</div>
			<div class="flex-grow flex flex-col p-4">
				<p
					class="flex-grow font-medium overflow-hidden whitespace-nowrap text-ellipsis"
					:title="props.asset.name"
				>
					<slot name="name" :asset-name="props.asset.name">
						{{ props.asset.name }}
					</slot>
				</p>
				<router-link
					v-if="props.showStorefrontName"
					:to="{ name: 'storefront', params: { slug: storefront.slug } }"
					:title="storefront.name"
					class="block text-gray-500 text-sm whitespace-nowrap overflow-hidden text-ellipsis"
				>
					{{ storefront.name }}
				</router-link>

				<p
					class="text-sm text-slate-700 mt-4 text-left [&+p]:mt-0"
					v-if="props.asset.floor_listing && props.showFloorListing"
				>
					<fa :icon="faTag" v-if="props.asset.floor_listing.listing_type === 'fixed'" />
					<fa :icon="faGavel" v-else />

					{{ $format(humanReadablePrice(listingPrice(props.asset.floor_listing))) }}
					{{ $token }}
					<span class="text-xs text-slate-400" v-if="props.asset.floor_listing.quantity > 1"
						>({{ props.asset.floor_listing.quantity }}x)</span
					>
				</p>
				<p
					class="text-sm text-slate-700 mt-4 text-left"
					v-if="props.asset.ceiling_offer && props.showCeilingOffer"
				>
					<fa :icon="faHandshake" />

					{{ $format(humanReadablePrice(props.asset.ceiling_offer.offer_bpx_per_token.toString())) }}
					{{ $token }}
				</p>
			</div>
			<slot name="footer">
				<Menu as="footer" class="absolute right-0 bottom-0">
					<MenuButton
						class="text-xs relative self-center cursor-pointer bg-white hover:bg-slate-100 p-4 rounded-tl-xl rounded-br-xl"
						ref="contextMenu"
						@click="toggleMenu"
					>
						<span class="block rotate-90 select-none">•••</span>
					</MenuButton>

					<transition
						enter-active-class="transition ease-out duration-100"
						enter-from-class="transform opacity-0 scale-95"
						enter-to-class="transform opacity-100 scale-100"
						leave-active-class="transition ease-in duration-75"
						leave-from-class="transform opacity-100 scale-100"
						leave-to-class="transform opacity-0 scale-95"
					>
						<div v-show="state.isMenuOpen" class="relative">
							<MenuItems
								ref="menuItems"
								static
								class="invisible bottom-12 right-0 absolute z-20 mt-2 w-[clamp(20ch,30ch,300px)] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none whitespace-nowrap overflow-hidden"
							>
								<div class="px-4 py-3">
									<p class="text-sm font-bold truncate">
										{{ props.asset.name }}
									</p>
								</div>

								<MenuItem v-slot="{ active }">
									<button
										type="button"
										:class="[
											active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
											'flex items-center px-4 py-2 text-sm text-left w-full',
										]"
										@click="handleFavorite(!isUsersFavoriteAsset, props.asset.asset_identifier)"
									>
										<div class="w-5 h-5 inline-block mr-2 -mt-0.5">
											<HeartIconSolid
												v-if="isUsersFavoriteAsset"
												class="inline-block h-5 w-5 text-red-500"
											/>
											<HeartIcon v-else class="inline-block h-5 w-5 text-gray-400" />
										</div>
										{{ isUsersFavoriteAsset ? 'Remove from' : 'Add to' }} Favorites
									</button>
								</MenuItem>

								<MenuItem v-slot="{ active }" v-if="isUserOwned">
									<button
										type="button"
										class="flex items-center px-4 py-2 text-sm text-left w-full disabled:text-gray-300 disabled:cursor-not-allowed"
										:class="[
											active
												? 'bg-gray-100 enabled:text-gray-900 enabled:cursor-pointer'
												: 'enabled:text-gray-700',
										]"
										:disabled="!marketStore.storeHasFeature(storefront.id, Flags.FEATURE_TRANSFERS)"
										@click="state.showTransferAssetModal = true"
									>
										<div class="w-5 h-5 inline-block mr-2 -mt-0.5">
											<ArrowsRightLeftIcon class="inline-block h-5 w-5 text-gray-400" />
										</div>
										Quick Transfer
									</button>
								</MenuItem>

								<MenuItem v-slot="{ active }" v-if="isUserOwned && !isInBasket">
									<button
										type="button"
										class="flex items-center px-4 py-2 text-sm text-left w-full disabled:text-gray-300 disabled:cursor-not-allowed"
										:class="[
											active
												? 'enabled:bg-gray-100 enabled:text-gray-900 enabled:cursor-pointer'
												: 'enabled:text-gray-700',
										]"
										:disabled="!marketStore.storeHasFeature(storefront.id, Flags.FEATURE_TRANSFERS)"
										@click="basketStore.addItem(props.asset.asset_identifier, 1, props.asset)"
									>
										<div class="w-5 h-5 inline-block mr-2 -mt-0.5">
											<ArchiveBoxArrowDownIcon class="inline-block h-5 w-5 text-gray-400" />
										</div>
										Select to Transfer
									</button>
								</MenuItem>

								<MenuItem v-slot="{ active }" v-else-if="isUserOwned && isInBasket">
									<button
										type="button"
										:class="[
											active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
											'flex items-center px-4 py-2 text-sm text-left w-full',
										]"
										@click="basketStore.removeItem(props.asset.asset_identifier)"
									>
										<div class="w-5 h-5 inline-block mr-2 -mt-0.5">
											<ArchiveBoxXMarkIcon class="inline-block h-5 w-5 text-gray-400" />
										</div>
										Remove from Transfer
									</button>
								</MenuItem>

								<MenuItem v-slot="{ active }" v-if="props.asset?.ceiling_offer">
									<button
										type="button"
										class="flex items-center px-4 py-2 text-sm text-left w-full disabled:text-gray-300 disabled:cursor-not-allowed"
										:class="[
											active
												? 'enabled:bg-gray-100 enabled:text-gray-900 enabled:cursor-pointer'
												: 'enabled:text-gray-700',
										]"
										:disabled="!marketStore.storeHasFeature(storefront.id, Flags.FEATURE_OFFERS)"
										@click="acceptOffer(props.asset?.ceiling_offer)"
									>
										<div class="w-5 h-5 inline-block mr-2 -mt-0.5">
											<fa :icon="faHandshake" class="inline-block h-5 w-5 text-gray-400" />
										</div>
										Accept Best Offer <br />
										<div class="text-xs text-slate-400 ml-auto">
											{{
												$format(
													humanReadablePrice(
														props.asset.ceiling_offer.offer_bpx_per_token.toString(),
													),
												)
											}}
											{{ $token }}
										</div>
									</button>
								</MenuItem>
							</MenuItems>
						</div>
					</transition>
				</Menu>
			</slot>
		</div>
		<transfer-asset-modal
			v-if="state.showTransferAssetModal"
			:asset="props.asset"
			:max-transferrable="props.balance.available"
			@close="state.showTransferAssetModal = false"
			@complete="handleAssetTransferred"
		/>
	</div>
</template>
<script lang="ts" setup>
import { Asset } from '@/types/Asset'
import { computed, withDefaults, reactive, ref } from 'vue'

import { useMarketStore } from '@/stores/MarketStore'
import { HeartIcon, ArrowsRightLeftIcon, ArchiveBoxArrowDownIcon, ArchiveBoxXMarkIcon } from '@heroicons/vue/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/vue/24/solid'
import TransferAssetModal from '@/components/modals/TransferAssetModal.vue'
import { humanReadablePrice } from '@/util/currencyFormat'

import { useUserFavoriteStore } from '@/stores/UserFavoriteStore'
import { onClickOutside } from '@vueuse/core'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import { nextTick } from 'vue'

import { useBulkTransferStore } from '@/stores/BulkTransferStore'
import { useRouter } from 'vue-router'
import { faTag, faGavel } from '@awesome.me/kit-428607b2fd/icons/classic/solid'
import { faHandshake } from '@awesome.me/kit-428607b2fd/icons/classic/light'
import { Offer } from '@/types/Storefront'
import { Flags } from '@/types/Flags'

const router = useRouter()
const basketStore = useBulkTransferStore()
const contextMenu = ref(null)
const menuItems = ref(null)
const ignoreElRef = ref(null)
onClickOutside(contextMenu, closeMenu, { ignore: [ignoreElRef] })

const $emit = defineEmits<{
	(event: 'asset-transferred', method: Asset): void
	(event: 'favorite', isFavorite: boolean): void
	(event: 'accept-offer', offer: Offer): void
}>()

const props = withDefaults(
	defineProps<{
		slug: string
		asset: Asset
		imageWidth?: number
		showStorefrontName?: boolean
		balance?: { balance: null | number; available: null | number }
		showFloorListing?: boolean
		showCeilingOffer?: boolean
	}>(),
	{
		imageWidth: 1024,
		showStorefrontName: false,
		balance: () => ({ balance: null, available: null }),
		showFloorListing: true,
		showCeilingOffer: false,
	},
)

const state = reactive({
	loaded: false,
	showTransferAssetModal: false,
	isMenuOpen: false,
})
const marketStore = useMarketStore()
const userFavStore = useUserFavoriteStore()
const storefront = computed(() => {
	return marketStore.byID[props.asset.storefront.id]
})

function handleAssetTransferred(asset) {
	state.showTransferAssetModal = false
	$emit('asset-transferred', asset)
}

const isInTransit = computed(() => props.balance && props.balance.available === 0)

function listingPrice(listing) {
	if (listing.listing_type == 'fixed') {
		return listing.quantity * listing.price
	}

	return Math.max(listing.high_bid_amount, listing.price)
}

const isUsersFavoriteAsset = computed(() => {
	return userFavStore.isAssetFavorited(props.asset.asset_identifier)
})

async function handleFavorite(isFavorite: boolean, assetId: string) {
	try {
		await userFavStore.favoriteAsset(isFavorite, assetId)
		$emit('favorite', isFavorite)
	} catch (e) {
		console.error(e)
	}
}

async function acceptOffer(offer: Offer | null) {
	if (offer) {
		$emit('accept-offer', offer)
	}
}

function toggleMenu() {
	state.isMenuOpen = !state.isMenuOpen
	nextTick(() => {
		if (state.isMenuOpen) {
			const menuRect = menuItems.value.el.getBoundingClientRect()
			const headerEl = document.querySelector('#site-header')
			const headerHeight = headerEl.getBoundingClientRect().height
			const viewportWidth = window.innerWidth
			const viewportHeight = window.innerHeight
			const bufferSpace = 10 // adjust this value as needed

			// Check if menu goes off the left side of the screen
			if (menuRect.left < 0) {
				menuItems.value.el.classList.add('left-0')
				menuItems.value.el.classList.remove('right-0')
			}

			// Check if menu goes off the right side of the screen
			if (menuRect.right > viewportWidth) {
				menuItems.value.el.classList.add('right-0')
				menuItems.value.el.classList.remove('left-0')
			}

			// Check if menu goes off the bottom of the screen
			if (menuRect.bottom > viewportHeight - bufferSpace) {
				menuItems.value.el.classList.add('bottom-12')
				menuItems.value.el.classList.remove('top-0')
			}

			// Check if menu is covered by the header
			if (menuRect.top < headerHeight + bufferSpace) {
				menuItems.value.el.classList.add('top-0')
				menuItems.value.el.classList.remove('bottom-12')
			}

			setTimeout(() => {
				menuItems.value.el.classList.add('!visible')
			}, 100)
		} else {
			menuItems.value.el.classList.remove('!visible')
		}
	})
}

function closeMenu() {
	state.isMenuOpen = false
}

const isUserOwned = computed(() => {
	return props.balance && props.balance.available > 0
})

const isInBasket = computed(() => {
	return (
		basketStore.basket.findIndex((item) => {
			return item.asset_id === props.asset.asset_identifier
		}) > -1
	)
})

function imageClick(event) {
	if (basketStore.isBasketModeEnabled) {
		event.preventDefault()
	}

	if (basketStore.isBasketModeEnabled && isInBasket.value) {
		basketStore.removeItem(props.asset.asset_identifier)
	} else if (basketStore.isBasketModeEnabled && !isInBasket.value) {
		try {
			basketStore.addItem(props.asset.asset_identifier, 1, props.asset)
		} catch (e) {
			basketStore.errorMsg = e.message
		}
	}
}
</script>
