import { defineStore } from 'pinia'
import api from '@/util/api'
import { Storefront } from '@/types/Storefront'
import { ExchangeRate } from '@/types/Currency'
import { useAuthStore } from './AuthStore'
import { Asset } from '@/types/Asset'
import { useFlagStore } from './FlagStore'
import { reactive, computed, ref } from 'vue'
import { Flag } from '@/types/Flags'

export const paymentMethods = {
	METHOD_BPX: 'BPX',
	METHOD_STORE_CREDIT: 'STORE-CREDIT',
}

type PaymentMethod = 'BPX' | 'STORE-CREDIT'

export const useMarketStore = defineStore('market', () => {
	const flagStore = useFlagStore()

	const storeFronts = ref<Storefront[]>([] as Storefront[])
	const featuredListings = ref<Asset[]>([] as Asset[])

	function init() {
		return {
			//state
			storeFronts,
			featuredListings,

			// actions
			load,
			loadFeaturedListings,
			placeBid,
			buyListing,
			otcTransfer,
			initiateStoreCreditPurchase,
			getMilesPackages,
			storeHasFeature,

			// getters
			byID,
		}
	}

	async function load(forceReload: boolean = false): Promise<Storefront[]> {
		if (forceReload || 0 == storeFronts.value.length) {
			storeFronts.value = await api.loadStorefronts()
		}

		return storeFronts.value
	}

	async function loadFeaturedListings(forceReload: boolean = false): Promise<Asset[]> {
		if (forceReload || featuredListings.value.length === 0) {
			featuredListings.value = await api.loadFeaturedListings()
		}

		return featuredListings.value as Asset[]
	}

	async function placeBid(
		paymentCurrencies: string[],
		bpxBid: string,
		listingID: string,
	): Promise<{ success: boolean; is_winning: boolean; high_bid: number; message: string; code?: number }> {
		return await api.placeBid(paymentCurrencies, bpxBid, listingID)
	}

	async function buyListing(
		paymentCurrencies: string[],
		listingID: string,
	): Promise<{ success: boolean; message: string; code?: number }> {
		return await api.buyListing(paymentCurrencies, listingID)
	}

	async function otcTransfer(
		assets: { asset_id: string; quantity: number }[],
		recipient: string,
		pin: string | null,
	): Promise<{ success: boolean; message: string; code?: number | string | null }> {
		return await api.transferAssets(assets, recipient, pin)
	}

	async function initiateStoreCreditPurchase(
		amount: number,
		redirect: string | null = null,
	): Promise<{ success: boolean; message?: string; redirect?: string }> {
		return await api.initiateStoreCreditPurchase(amount, redirect)
	}

	async function getMilesPackages() {
		return await api.getMilesPackages()
	}

	function storeHasFeature(id: string, feature: Flag): boolean {
		load()
		let sf = byID.value[id] ?? null

		if (!sf) {
			sf = storeFronts.value.find((s) => s.slug == id)

			if (!sf) {
				return false
			}
		}

		const response = flagStore.check(sf.config?.features ?? [], feature)

		return response
	}

	const byID = computed(() => {
		if (storeFronts.value.length == 0) {
			return {}
		}

		return storeFronts.value.reduce((all: any, storefront: any) => {
			all[storefront.id] = storefront
			return all
		}, {})
	})

	return init()
})
