<template>
	<div
		:key="`${drop.season}-${drop.sport}-${drop.drop ?? 0}`"
		class="rounded-lg grid md:grid-cols-[3fr,4fr,2fr] gap-x-4 bg-white border border-black/10 relative"
	>
		<div :id="props.anchor" class="absolute top-0 left-0 -mt-44 lg:-mt-48"></div>

		<div class="aspect-[1.1] rounded-t-lg lg:rounded-l-lg lg:rounded-r overflow-hidden shadow-xl" :class="boxBg">
			<div
				:style="{ 'background-image': `url(${drop.boxImage})` }"
				:title="drop.name"
				class="size-full bg-cover bg-center"
				v-if="drop.id"
			></div>
			<div v-else class="size-full grid place-content-center uppercase text-gray-500">Coming soon</div>
		</div>

		<div class="px-4 lg:px-0 py-4 overflow-x-hidden">
			<div class="font-poppins">
				<span class="inline-block text-2xl/10 lg:text-3xl/12 align-middle mr-2"
					>{{ drop.sport }} {{ drop.season }}</span
				>
				<span
					v-if="drop.drop ?? false"
					class="inline-block bg-gray-200 text-gray-700 text-sm font-medium align-middle py-2 px-3 mr-2 rounded-full uppercase"
					>Drop {{ drop.drop }}</span
				>
				<a
					class="inline-block text-gray-400 hover:text-gray-600 active:text-gray-600 text-sm font-medium align-middle transition-colors"
					:href="`/hometeamheroes/${props.anchor}`"
				>
					#
				</a>
			</div>
			<div>
				{{ isAfter(new Date(), new Date(drop.openable_at)) ? 'Released' : 'Releases' }}
				{{ drop.openable_at ? format(new Date(drop.openable_at + 'T12:00:00Z'), 'MMMM do, yyyy') : 'TBD' }}
			</div>

			<div class="mt-2 flex flex-wrap gap-2 lg:gap-4 select-none">
				<div v-if="drop.heroes" class="text-center p-4 rounded-lg border border-gray-100">
					<div class="text-xl lg:text-2xl font-medium text-gray-800">
						{{ drop.heroes.toLocaleString() }}
					</div>
					<div class="text-xs uppercase text-gray-600">Heroes</div>
				</div>

				<div v-if="drop.card_count" class="text-center p-4 rounded-lg border border-gray-100">
					<div class="text-xl lg:text-2xl font-medium text-gray-800">
						{{ drop.card_count.toLocaleString() }}
					</div>
					<div class="text-xs uppercase text-gray-600">Cards</div>
				</div>

				<div
					v-if="!drop.soldOut && drop.cards?.total && drop.cards?.revealed"
					class="text-center p-4 rounded-lg border border-gray-100"
				>
					<div class="text-xl lg:text-2xl font-medium text-gray-800">{{ drop.cards.revealed }}%</div>
					<div class="text-xs uppercase text-gray-600">Ripped</div>
				</div>
			</div>

			<div class="mt-2 overflow-x-hidden" v-if="allRares.length">
				<div class="text-sm uppercase font-bold">Still In Packs</div>
				<div class="h-24 overflow-hidden relative">
					<transition-group
						tag="div"
						v-for="(card, i) in showRares"
						:key="card.asset_id"
						:css="false"
						class="absolute top-0 bottom-0 block rounded aspect-[3/4] bg-gray-200 transition-all duration-500 h-24"
						:style="{
							left: (i - 1) * 5 + 'rem',
						}"
					>
						<a
							:href="'/store/' + card.storefront_slug + '/' + card.token_id"
							:title="card.name"
							:key="card.asset_id"
						>
							<img
								:key="card.asset_id"
								class="block rounded bg-cover h-full w-full"
								:src="$cdnify(card.image, 256, 256)"
								:alt="card.name"
							/>
						</a>
					</transition-group>
				</div>
			</div>
		</div>

		<div class="px-4 lg:pl-0 py-4 space-y-2 self-center">
			<div
				v-if="drop.soldOut"
				class="w-full bg-gradient-to-tr from-gray-200 to-gray-200 text-gray-500 font-medium text-center py-3 px-6 rounded-full flex items-center justify-center gap-1 select-none cursor-not-allowed"
			>
				Sold Out
			</div>
			<button
				v-else-if="canBuyPacks"
				type="button"
				aria-label="Buy Packs"
				class="w-full bg-gradient-to-tr from-sky-600 to-sky-500 hover:saturate-150 text-white text-lg font-medium text-center py-3 px-6 rounded-full hover:scale-105 shadow-md transition-all grid grid-cols-[2rem,1fr] items-center justify-center gap-1"
				@click="() => (showBuyPacks = true)"
			>
				<div><i class="far fa-shopping-cart"></i></div>
				<div>Buy Packs</div>
			</button>
			<div
				v-else
				class="w-full bg-gradient-to-tr from-gray-200 to-gray-200 text-gray-500 font-medium text-center py-3 px-6 rounded-full flex items-center justify-center gap-1"
			>
				Not Available
			</div>

			<a
				v-if="setBuilderUrl"
				:href="setBuilderUrl"
				aria-label="Set Builder"
				target="_blank"
				class="w-full bg-white text-black border border-black/20 hover:border-black/60 font-medium text-center py-2 px-6 rounded-full hover:scale-105 shadow-md transition-all grid grid-cols-[2rem,1fr] items-center justify-center gap-1"
			>
				<div><i class="far fa-cards-blank"></i></div>
				<div>Set Builder</div>
			</a>

			<a
				v-if="baseSetUrl"
				:href="baseSetUrl"
				aria-label="Base Set in Blokpax Marketplace"
				class="w-full bg-white text-black border border-black/20 hover:border-black/60 font-medium text-center py-2 px-6 rounded-full hover:scale-105 shadow-md transition-all grid grid-cols-[2rem,1fr] items-center justify-center gap-1"
			>
				<div><i class="far fa-store"></i></div>
				<div>Base Set</div>
			</a>

			<a
				v-if="colorPopsUrl"
				:href="colorPopsUrl"
				aria-label="Color Pops in Blokpax Marketplace"
				class="w-full bg-white text-black border border-black/20 hover:border-black/60 font-medium text-center py-2 px-6 rounded-full hover:scale-105 shadow-md transition-all grid grid-cols-[2rem,1fr] items-center justify-center gap-1"
			>
				<div><i class="far fa-store"></i></div>
				<div>Color Pops</div>
			</a>
		</div>

		<div
			class="absolute left-0 bottom-0 right-0 lg:left-auto lg:right-0 lg:top-0 lg:bottom-0 w-full lg:w-2/3 h-2/3 lg:h-full bg-white/80 backdrop-blur-lg py-4 px-4 transition-all rounded-b-lg lg:rounded-r-lg"
			:class="{
				'translate-y-full': !showBuyPacks,
				'lg:translate-y-0': !showBuyPacks,
				'lg:translate-x-full': !showBuyPacks,
				'opacity-0': !showBuyPacks,
			}"
		>
			<div class="absolute top-4 right-4">
				<button @click="() => (showBuyPacks = false)" class="fill-current size-4">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
						<path
							d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
						/>
					</svg>
				</button>
			</div>

			<div class="font-poppins">
				<span class="inline-block text-2xl/10 lg:text-3xl/12 align-middle mr-2"
					>Buy {{ drop.sport }} {{ drop.season }}</span
				>
				<span
					v-if="drop.drop ?? false"
					class="inline-block bg-gray-200 text-gray-700 text-sm font-medium align-middle py-2 px-3 rounded-full uppercase"
					>Drop {{ drop.drop }}</span
				>
			</div>

			<div class="flex flex-col lg:flex-row gap-4">
				<div v-for="item in availableItems ?? []" class="flex-1 flex flex-col pt-4 gap-4">
					<div class="grow">
						<div class="text-xl font-poppins">{{ item.short_name ?? item.name }}</div>
						<div class="text-sm">
							{{ item.description }}
						</div>
					</div>

					<div
						class="flex flex-row lg:flex-col-reverse gap-4 justify-items-center items-center lg:items-start"
					>
						<div class="flex w-min text-center place-items-center bg-white p-1 lg:p-2 rounded-full">
							<button
								@click="decrItem(item.id)"
								class="bg-slate-100 text-slate-800 hover:bg-sky-600 hover:text-white hover:saturate-150 transition-all rounded-full size-12 text-base lg:text-lg grid place-items-center flex-none touch-manipulation"
							>
								<i class="far fa-cart-minus" />
							</button>
							<div class="font-medium text-lg lg:text-xl w-12 lg:w-16 text-center">
								{{ selectedQty(item.id) }}
							</div>
							<button
								@click="incrItem(item.id)"
								class="bg-slate-100 text-slate-800 hover:bg-sky-600 hover:text-white hover:saturate-150 transition-all rounded-full size-12 text-base lg:text-lg grid place-items-center flex-none touch-manipulation"
							>
								<i class="far fa-cart-plus" />
							</button>
						</div>

						<div class="font-medium text-lg lg:text-xl lg:text-left">
							${{ currencyFormat(item.price / 100) }} each
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { isAfter, format } from 'date-fns'
import { onMounted, onUnmounted, computed, ref, type PropType, Prop, shallowRef, reactive } from 'vue'

import { msleep } from '@/util/sleep'
import { Drop, UnrevealedCard, UnrevealedColorPop } from '@/types/Drop'
import currencyFormat from '@/util/currencyFormat'
import { type OrderItem } from '@/types/Item'

const props = defineProps({
	drop: {
		type: Object as PropType<Drop>,
		required: true,
	},
	anchor: {
		type: String,
		required: true,
	},
	show: {
		type: Number,
		default: 6,
	},
	delay: {
		type: Number,
		default: 0,
	},
	timer: {
		type: Number,
		default: 5000,
	},
})

const emit = defineEmits<{
	(e: 'quantity-changed', oi: OrderItem): void
}>()

const allRares = shallowRef<Array<UnrevealedColorPop | UnrevealedCard>>([])
const counter = ref<number>(0)
let interval = null

const showBuyPacks = ref<boolean>(false)
const qtys = reactive<object>({})

onMounted(async () => {
	allRares.value = buildRares()
	await msleep(props.delay)

	if (allRares.value.length > props.show) {
		interval = window.setInterval(() => {
			counter.value++
		}, props.timer)

		counter.value++
	}
})

onUnmounted(() => {
	if (interval !== null) {
		window.clearInterval(interval)
		interval = null
	}
})

function buildRares(): Array<UnrevealedColorPop | UnrevealedCard> {
	let ret = Array<UnrevealedColorPop | UnrevealedCard>()

	if (props.drop.unrevealedCards) {
		ret.push(...props.drop.unrevealedCards.cards)
	}

	if (props.drop.unrevealedColorPops) {
		ret.push(...props.drop.unrevealedColorPops.cards)
	}

	ret = ret.sort(() => Math.random() * 2 - 1)

	return ret
}

const showRares = computed<Array<UnrevealedCard | UnrevealedColorPop>>(
	(): Array<UnrevealedCard | UnrevealedColorPop> => {
		const show = props.show + 2
		const start = counter.value % allRares.value.length

		const ret = Array<UnrevealedCard | UnrevealedColorPop>(0)

		for (let i = start; ret.length < show && i < allRares.value.length; i++) {
			ret.push(allRares.value[i])
		}

		for (let i = 0; ret.length < show; i++) {
			ret.push(allRares.value[i])
		}

		return ret
	},
)

const canBuyPacks = computed(() => {
	return !props.drop.soldOut && props.drop.sales.length > 0
})

const boxBg = computed<string>((): string => {
	switch (props.drop.sport) {
		case 'Baseball':
			return 'bg-gradient-to-t from-sky-700 to-sky-500'

		case 'Football':
			return 'bg-gradient-to-t from-red-900 to-red-600'

		case 'Basketball':
			return 'bg-gradient-to-t from-orange-800 to-orange-600'

		default:
			return 'bg-gradient-to-t from-gray-300 to-gray-200'
	}
})

const baseSetUrl = computed<string>((): string => {
	if (!isAfter(new Date(), new Date(props.drop.openable_at))) {
		return ''
	}

	return `/store/hth-${props.drop.sport}-${props.drop.season}-base-set`.toLowerCase()
})

const colorPopsUrl = computed<string>((): string => {
	if (!isAfter(new Date(), new Date(props.drop.openable_at))) {
		return ''
	}

	return `/store/hth-${props.drop.sport}-${props.drop.season}-color-pops`.toLowerCase()
})

const setBuilderUrl = computed<string>((): string => {
	return props.drop.setBuilder ?? ''
})

const availableItems = computed(() => {
	let tbr = []
	for (const sale of props.drop?.sales ?? []) {
		for (const item of sale.items ?? []) {
			for (const collection of item.collections) {
				if (collection.collection_id === props.drop.id) {
					tbr.push(item)
				}
			}
		}
	}
	return tbr
})

const selectedQty = (id: string) => qtys[id] ?? 0

function incrItem(id: string) {
	if (qtys[id] == undefined) {
		qtys[id] = 0
	}

	qtys[id]++

	emit('quantity-changed', {
		...availableItems.value.find((item) => item.id === id),
		quantity: qtys[id],
	})
}

function decrItem(id: string) {
	if (qtys[id] == undefined) {
		qtys[id] = 0
	}

	if (qtys[id] - 1 >= 0) {
		qtys[id]--
	}

	emit('quantity-changed', {
		...availableItems.value.find((item) => item.id === id),
		quantity: qtys[id],
	})
}
</script>
